/**
 * Defines actions and action creators related to flash messages.
 */
//
// - Actions and Sync Action Creators
//
import type { Glyphs } from '~/components/icon'

export type MessageTypes = 'info' | 'message' | 'alert' | 'error'
export type MessageContent = string
export type MessageOptions = Record<string, any> & {
  id?: string
  navCount?: number
  glyph?: Glyphs
  persistent?: boolean
  reload?: boolean
  autoHide?: boolean
  exclusive?: boolean
}

export type FlashMessage = {
  type: MessageTypes
  text: MessageContent
  options: MessageOptions
}

export type FlashActions =
  | {
      type: 'REMOVE_FLASH_MESSAGE'
      msg: FlashMessage
    }
  | {
      type: 'ADD_FLASH_MESSAGE'
      msg: FlashMessage
    }

export const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE'
export function onFlashMessageRemove(msg: FlashMessage): FlashActions {
  return {
    type: REMOVE_FLASH_MESSAGE,
    msg,
  }
}

export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE'
export function addFlashMessage(
  type: MessageTypes,
  text: MessageContent,
  options: MessageOptions = {},
): FlashActions {
  return {
    type: ADD_FLASH_MESSAGE,
    msg: {
      type,
      text,
      options,
    },
  }
}
