import type { CustomerAPIEndpoints } from '~/types'

export default function getCustomerAPIEndpoints(): CustomerAPIEndpoints {
  return {
    affirmCheckoutPath: '/api/v2/cart/affirm-checkout',
    cartAddonsPath: '/api/v2/cart/addons',
    cartCreditCardPath: '/api/v2/cart/credit-card',
    cartItemPath: (internalId: string) => `/api/v2/cart/items/${internalId}`,
    cartItemsAssignPath: (internalId: string) =>
      `/api/v2/cart/items/${internalId}/assign`,
    cartItemsPath: '/api/v2/cart/items',
    cartItemPromotionPromptPath: (internalId: string) =>
      `/api/v2/cart/items/${internalId}/promotion-prompts`,
    cartPromotionsPath: '/api/v2/cart/promotion',
    cartUpgradePath: '/api/v2/cart/upgrade',
    cartWaiversPath: '/api/v2/cart/waivers',
    creditsPath: '/api/v2/credits',
    preferencesPath: (profileId: string) =>
      `/api/v2/profiles/${profileId}/preferences`,
    profilePath: (id: string) => `/api/v2/profiles/${id}`,
    guestPath: '/api/v2/me',
    guestProfilesPath: '/api/v2/profiles',
    ordersPath: '/api/v2/orders',
    redemptionsPath: '/api/v2/my-redemptions',
    profileMediaPath: (profileId: string, activeMediaOnly?: boolean) => {
      let path = `/api/v2/profiles/${profileId}/media`
      return activeMediaOnly ? (path += `?active_media_only=true`) : path
    },
    profileProductsPath: '/api/v2/my-products',
    addUpgradesToCartPath: '/api/v2/cart/upgrades',
    profileProductUpdatePath: (profileProductId: number) =>
      `/api/v2/my-products/${profileProductId}`,
    reservationPath: (reservationId: number) =>
      `/api/v2/reservations/${reservationId}`,
    reservationsPath: '/api/v2/reservations',
    reservationCartConfirmPath: '/api/v2/reservation-cart/confirm',
    reservationCartPath: '/api/v2/reservation-cart',
    reservationAvailabilityPath: (resortId: number | string) =>
      `/api/v2/reservation-availability/${resortId}`,
    acknowledgeCartChangePath: '/api/v2/cart/changed',
    waiversPath: '/api/v2/waivers',
    voucherShareHistoryPath: (profileProductId: number) =>
      `/api/v2/voucher-shares/events?profile_product_id=${profileProductId}`,
    voucherSharePath: '/api/v2/voucher-shares',
    claimVoucherSharePath: '/api/v2/voucher-shares/claim',
    returnVoucherSharePath: '/api/v2/voucher-shares/revoke',
    notificationsPath: '/api/v2/notifications',
    notificationDismissPath: (notificationId: number) =>
      `/api/v2/notifications/${notificationId}/dismiss`,
    redeemableVouchersPath: '/api/v2/voucher-shares/redeemable-vouchers',
    facebookConversionsPath: '/api/v2/facebook-conversions',
    cartTargetedUpsellPath: '/api/v2/cart/targeted-upsell',
    freedomPayHpcSessionInitializePath: '/api/v2/freedom-pay-hpc/session',
    freedomPayHpcCreateCreditCardPath: '/api/v2/freedom-pay-hpc/credit-card',
    sheerIdWebhookPath: '/api/v2/sheer-id-webhook',
    promotionCodeAutoApplicationPath: (promotionCode?: string) => {
      return promotionCode
        ? `/api/v2/promotion-code-auto-application?promotion_code=${promotionCode}`
        : `/api/v2/promotion-code-auto-application`
    },
    creditCardOnFilePath: '/api/v2/credit-card-on-file',
    useCreditCardOnFilePath: '/api/v2/cart/credit-card/use-card-on-file',
    benefitsPath: (profileProductId: number) =>
      `/api/v2/my-products/${profileProductId}/benefits`,
    passUsagePath: (profileProductId: number) =>
      `/api/v2/my-products/${profileProductId}/pass-usage`,
    sharedVouchersSummaryPath: (profileProductId: number) =>
      `/api/v2/my-products/${profileProductId}/shared-vouchers-summary`,
    minorConsentsPath: `/api/v2/minor-consents`,
  }
}
