import { I18n } from 'i18n-js'

import { LANGUAGE_PACK, LOCALE } from './config'

export const i18n = new I18n({ [LOCALE]: LANGUAGE_PACK })
i18n.locale = LOCALE || 'en'

const pluralizationFunction = function (count) {
  if (count === 0) return ['zero', 'other']
  if (count === 1) return ['one']
  if (count === Infinity) return ['infinity']

  return ['other']
}

i18n.pluralization['en'] = pluralizationFunction
i18n.pluralization['fr'] = pluralizationFunction
