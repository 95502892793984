import HTTPError from './http-error'

type Success = (data: Record<string, any>) => void
type Failure = (
  error:
    | Error
    | {
        statusMessage: string
      },
) => void
type Progress = (progress: number) => void

let cloudinaryApiUrl = ''
let cloudinaryImageUrl = ''

export function setUploadApiUrl(newUrl: string) {
  cloudinaryApiUrl = newUrl
}
export function setUploadImageUrl(newUrl: string) {
  cloudinaryImageUrl = newUrl
}

export function getUploadImageUrl() {
  return cloudinaryImageUrl
}

export function validateFaces(
  data: Record<string, any>,
  success: Success,
  failure: Failure,
) {
  if (!data.faces || data.faces.length === 0) {
    return failure({
      statusMessage: 'no_face',
    })
  } else if (data.faces.length > 1) {
    return failure({
      statusMessage: 'multiple_faces',
    })
  } else {
    return success(data)
  }
}

export default function cloudinaryUpload(
  file: File,
  success: Success,
  failure: Failure,
  progress: Progress,
) {
  const data = new window.FormData()
  data.append('file', file)
  data.append('upload_preset', 'amp-pass-photo')
  const xhr = new window.XMLHttpRequest()
  const url = `${cloudinaryApiUrl}/image/upload`
  xhr.open('POST', url)

  xhr.onload = () => {
    const status = xhr.status
    const text = xhr.responseText

    if (status === 200) {
      validateFaces(JSON.parse(text), success, failure)
    } else {
      failure(
        new HTTPError(
          'Unable to upload photo',
          new Response(text, {
            status,
          }),
          text,
        ),
      )
    }
  }

  // @ts-expect-error TS2322
  xhr.onerror = failure

  xhr.upload.onprogress = (e) => {
    progress && progress(e.loaded / e.total)
  }

  xhr.send(data)
  return xhr
}
