import { camelizeKeys } from 'humps'

import type { Reservation, API$Reservation } from '~/types'

export default function reservationsDeserializer(
  reservations: API$Reservation[],
): Reservation[] {
  return (
    reservations
      .map((reservation) => camelizeKeys(reservation))
      // @ts-expect-error TS2362
      .sort((a, b) => new Date(a.date) - new Date(b.date))
  )
}
