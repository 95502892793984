/*
 * Date helpers
 */
import { differenceInYears, isFuture, isToday } from 'date-fns'
import moment from 'moment'

import { MIN_TEEN } from './validations.constants'

function range(from: number, to: number): number[] {
  if (from > to) {
    throw new Error('negative range')
  }

  const result = []

  for (let i = from; i <= to; i++) {
    result.push(i)
  }

  return result
}

function pad(number: number): string {
  return ('0' + number).slice(-2)
}

export const CURRENT_YEAR = new Date().getUTCFullYear()

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const DAYS: string[] = range(1, 31).map(pad)

export const YEARS: number[] = range(CURRENT_YEAR - 100, CURRENT_YEAR).reverse()

export const CARD_MONTHS: string[] = range(1, 12).map(pad)

export const CARD_YEARS = range(CURRENT_YEAR, CURRENT_YEAR + 20)

export const RELOAD_YEARS = [CURRENT_YEAR, CURRENT_YEAR + 1]

/*
 * @param  {string} dateString  ISO Date string
 * @return {object}             year, (zero-indexed) month, day date components
 */
export function parseDate(dateString: string | null | undefined) {
  if (!dateString) {
    return {
      year: undefined,
      month: undefined,
      day: undefined,
    }
  }

  const date = moment.utc(dateString, 'YYYY MM DD').toDate()
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth(),
    day: date.getUTCDate(),
  }
}

/*
 * @param  {object} date      year, month (zero-indexed), day date components
 * @return {string}           ISO Date string
 */
export function stringifyDate({
  year,
  month,
  day,
}: {
  year: (number | null | undefined) | (string | null | undefined)
  month: (number | null | undefined) | (string | null | undefined)
  day: (number | null | undefined) | (string | null | undefined)
}) {
  const numYear: number | null | undefined =
    typeof year === 'string' ? parseInt(year, 10) : year
  const numMonth: number | null | undefined =
    typeof month === 'string' ? parseInt(month, 10) : month
  const numDay: number | null | undefined =
    typeof day === 'string' ? parseInt(day, 10) : day
  if (numYear == null || isNaN(numYear)) return ''
  if (numMonth == null || isNaN(numMonth)) return ''
  if (numDay == null || isNaN(numDay)) return ''
  const isoFmt = new Date(Date.UTC(numYear, numMonth, numDay)).toISOString()
  const [date] = isoFmt.split('T')
  return date
}

export function removeHours(date: string | Date) {
  const dateObj = new Date(date)
  dateObj.setHours(0, 0, 0, 0)
  return dateObj
}

export function ageNow(dob: string) {
  return differenceInYears(Date.now(), removeHours(dob))
}

export function ageAtGivenDate(dob: string, givenDate: string | Date) {
  return differenceInYears(removeHours(givenDate), removeHours(dob))
}

export function stringifyEpochDate(epochDate: number | string) {
  if (!epochDate) return ''
  return new Date(epochDate).toISOString()
}

export const getCurrentDate = (): Date => new Date()

export const getMinAccountAge = () => MIN_TEEN

export const underAge = (dob: string): boolean => {
  return ageNow(dob) < getMinAccountAge()
}

export const convertLocalTimezoneDateToDateString = (
  date: Date,
  timezoneOffset: number = date.getTimezoneOffset() * 60000,
) => {
  return new Date(date.getTime() - timezoneOffset)
    .toISOString()
    .substring(0, 10)
}

export const convertDateStringToLocalTimezoneDate = (
  dateString: string,
  timezoneOffset: number = new Date(dateString).getTimezoneOffset() * 60000,
) => {
  const twelveHours = 12 * 60 * 60 * 1000
  return new Date(new Date(dateString).getTime() + timezoneOffset + twelveHours)
}

export const redemptionDateHelper = (redeemDate: string): Date => {
  // Handle "2020-01-01T12:00:00" format
  const newDateFormatRegex = /(?<year>\d{4})-(?<month>\d\d)-(?<day>\d\d)T.*/

  // Handle "06/31/2020 10:26:05 AM" format
  const legacyDateFormatRegex = /(?<month>\d+)\/(?<day>\d+)\/(?<year>\d{4}).*/

  const match =
    redeemDate.match(newDateFormatRegex) ||
    redeemDate.match(legacyDateFormatRegex)

  const redeemMonth = parseInt(match.groups['month'])

  const redeemYear = parseInt(match.groups['year'])

  const redeemDay = parseInt(match.groups['day'])

  return new Date(redeemYear, redeemMonth - 1, redeemDay)
}

export const isPastDate = (date: Date) =>
  convertLocalTimezoneDateToDateString(date) <
  convertLocalTimezoneDateToDateString(new Date())

export const isBeforeDeadline = (deadline: string) => {
  if (!deadline) return false
  let [year, month, day] = deadline.split('-').map((n) => parseInt(n))
  month -= 1 // month is zero-indexed for `new Date(yyyy, mm, dd)`

  const deferrableDateObj = new Date(year, month, day)
  return isToday(deferrableDateObj) || isFuture(deferrableDateObj)
}
