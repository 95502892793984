export default {
  white: '#FFF',
  coolGrey: '#D9D9D9',
  coolGreyLighter: '#F0F0EF',
  lightestGrey: '#F8F8F8',
  lighterGrey: '#F4F4F4',
  lightGrey: '#F1F1F1',
  mediumGrey: '#6A6C69',
  slateGrey: '#9DA7B3',
  darkGrey: '#373A36',
  darkestGrey: '#1D1F1F',
  black: '#000',
  brightBlue: '#089ADE',
  blueCreek: '#007DB7',
  blueCreekDark: '#006695',
  blueIris: '#089ade',
  ikonBlue: '#092340',
  red: '#AD4A4A',
  green: '#278189',
  ikonGold: '#ffc72c',
}
