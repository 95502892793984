import { endOfMonth, isAfter } from 'date-fns'

import { i18n } from '~/i18n'

import type { Glyphs } from '../components/icon'
import type { CreditCardType, CartState, TokenizedCreditCard } from '../types'

const DISCOVER = 'discover'
const VISA = 'visa'
const AMEX = 'american_express'
const MASTER = 'master'

export const CARD_TYPES_USD = [DISCOVER, VISA, AMEX, MASTER]
export const CARD_TYPES_CAD = [MASTER, VISA]

export function getCardTypes(cart: CartState): CreditCardType[] {
  if (cart.payloadCurrency === 'USD') return [DISCOVER, VISA, AMEX, MASTER]

  if (
    cart.payloadCurrency == 'CAD' &&
    (cart.purchaseGroup === 'blue' || cart.purchaseGroup === 'tremblant')
  ) {
    return [MASTER, VISA, AMEX]
  }

  return [MASTER, VISA]
}

export function iconGlyphForCreditCardType(creditCardType: string): Glyphs {
  const creditCardBrandToGlyphMap = {
    visa: 'cc-visa',
    master: 'cc-mastercard',
    american_express: 'cc-amex',
    discover: 'cc-discover',
  }
  return creditCardBrandToGlyphMap[creditCardType]
}

export function creditCardExpired(
  creditCard: TokenizedCreditCard,
  currentDate: Date = new Date(),
): boolean {
  const expiryDate = new Date(
    parseInt(creditCard.year),
    parseInt(creditCard.month) - 1,
  )
  return isAfter(currentDate, endOfMonth(expiryDate))
}

type CreditCartErrors = {
  credit_card?: string[]
  reason_code?: string[]
}

export const getErrorMessage = (
  errors: CreditCartErrors,
  fallback: string = i18n.t('pages.payment.credit_card.tokenization_failed'),
) => {
  const creditCardErrorCode = errors?.credit_card?.[0]
  const reasonCode = errors?.reason_code?.[0]
  const reasonCodeTranslation = reasonCode
    ? i18n.t(`errors.freedompay.${reasonCode}`, { defaultValue: fallback })
    : fallback

  switch (creditCardErrorCode) {
    case 'address_validation_error':
      return i18n.t('pages.payment.credit_card.avs_failed')

    case 'invalid_cvc':
      return i18n.t('pages.payment.credit_card.cvc.invalid')

    default:
      return reasonCodeTranslation
  }
}
