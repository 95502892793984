import { styled } from 'styled-components'

import Icon from '~/components/icon'

export const Input = styled.input`
  // this is necessary to override the style rule in ~/components/test-input/text-input.scss
  && {
    padding-right: 0;
  }

  &::-webkit-credentials-auto-fill-button,
  &::-ms-reveal,
  &::-ms-clear {
    visibility: hidden;
    display: none;
    height: 0;
    width: 0;
    margin: 0;
  }
`

export const ToggleShowPassword = styled(Icon)`
  cursor: pointer;
`
