import { camelizeKeys } from 'humps'

import { addFlashMessage } from '~/actions/flash-message'
import { CAPTURE_EMAIL_ADDRESS_AGE } from '~/config'
import { i18n } from '~/i18n'
import { getFeatureFlags } from '~/selectors/feature-flags'
import { getCustomerAPI } from '~/utils/api'
import { createMinorConsents } from '~/utils/api/minor-consent'
import { ageNow } from '~/utils/date'
import HTTPError from '~/utils/http-error'
import {
  hasErrors,
  validateProfileDetailsEditInformation,
  BASE_ERROR_KEY,
} from '~/utils/validations'

import { changeCartParticipant } from './cart'
import { loadGroup } from './group'
import { loadProfile } from './profile'
import { reportGroupedError } from '../utils/logger'

import type { Saved as PhotoUploadSaved } from './photo-upload'
import type { AppState } from '~/reducers'
import type {
  API,
  AppDispatch,
  CloudinaryPhoto,
  CustomerAPI,
  ProfileDetailsEdit,
} from '~/types'
import type { ErrorCode } from '~/utils/validations'

type ProfileDetailsEditOptional = {
  id?: string | null | undefined
  dob?: string
  firstName?: string
  lastName?: string
  email?: string
  gender?: string
  minorConsentApproved?: boolean
}

export const UPDATE_PROFILE_DETAILS_EDIT = 'UPDATE_PROFILE_DETAILS_EDIT'
export const UPDATE_CLOUDINARY_DETAILS = 'UPDATE_CLOUDINARY_DETAILS'
export const TOGGLE_FIELD_EDITABLE = 'TOGGLE_FIELD_EDITABLE'

export type GroupMemberDetails = ProfileDetailsEditOptional & {
  errors?: Record<string, ErrorCode>
  translatedErrors?: Record<string, string>
  editableFields?: Array<keyof ProfileDetailsEditOptional>
}

export type EditableFields =
  | 'firstName'
  | 'lastName'
  | 'dob'
  | 'email'
  | 'gender'
  | 'minorConsentApproved'

type UpdateDetails = {
  type: 'UPDATE_PROFILE_DETAILS_EDIT'
  data: GroupMemberDetails
}
type UpdateCloudinaryDetails = {
  type: 'UPDATE_CLOUDINARY_DETAILS'
  data: CloudinaryPhoto
}
type ToggleFieldEditable = {
  type: 'TOGGLE_FIELD_EDITABLE'
  field: EditableFields
  editable: boolean
}

export type Action =
  | UpdateDetails
  | UpdateCloudinaryDetails
  | ToggleFieldEditable
  | PhotoUploadSaved

export function updateProfileDetails(data: GroupMemberDetails): UpdateDetails {
  return {
    type: UPDATE_PROFILE_DETAILS_EDIT,
    data,
  }
}

export function toggleFieldEditable(
  field: EditableFields,
  editable: boolean,
): ToggleFieldEditable {
  return {
    type: TOGGLE_FIELD_EDITABLE,
    field,
    editable,
  }
}

function createAndAssignProfile(
  profile: ProfileDetailsEdit,
  internalId: string,
  api: API = getCustomerAPI(),
  reload: (...args: Array<any>) => any = performDefaultReloadTasks,
  _createProfile: typeof createProfile = createProfile,
) {
  return async (dispatch: AppDispatch): Promise<string | null | undefined> => {
    const profileId = await dispatch(_createProfile(profile, api, reload))
    if (profileId) dispatch(changeCartParticipant(internalId, profileId, api))
    return profileId
  }
}

export function createProfile(
  profile: ProfileDetailsEdit,
  api: API = getCustomerAPI(),
  reload: (...args: Array<any>) => any = performDefaultReloadTasks,
) {
  const request = () => api.createProfile(profile)

  return performProfileCreateOrUpdateRequest(request, reload)
}

export function updateProfile(
  profile: ProfileDetailsEdit,
  api: CustomerAPI = getCustomerAPI(),
): () => Promise<string | null | undefined> {
  const request = () => api.updateProfile(profile)

  // @ts-expect-error TS2322
  return performProfileCreateOrUpdateRequest(request)
}

function performDefaultReloadTasks() {
  return (dispatch: AppDispatch) => {
    return Promise.all([
      dispatch(
        loadGroup({
          reload: true,
        }),
      ),
      dispatch(
        loadProfile({
          reload: true,
        }),
      ),
    ])
  }
}

let saving = false

function performProfileCreateOrUpdateRequest(
  request: () => Promise<Response>,
  reload: (...args: Array<any>) => any = performDefaultReloadTasks,
) {
  return async (dispatch: AppDispatch): Promise<string | null | undefined> => {
    if (saving) return
    saving = true

    let profileId

    try {
      const resp = await request()

      if (resp.ok) {
        await dispatch(reload())

        dispatch(
          addFlashMessage(
            'info',
            i18n.t('components.flash_messages.profile_created'),
            {
              glyph: 'review',
              autoHide: true,
            },
          ),
        )

        if (resp.status === 201) {
          const { data } = await resp.json()
          profileId = data && data.id
        }
      } else if (resp.status === 422) {
        const { errors } = await resp.json()
        dispatch(
          updateProfileDetails({
            translatedErrors: camelizeKeys(errors),
          }),
        )
      } else {
        const rawBody = await resp.text()
        const error = new HTTPError('profile creation failed', resp, rawBody)
        throw error
      }
    } catch (error) {
      reportGroupedError('performProfileCreateOrUpdateRequest', error)
      dispatch(
        updateProfileDetails({
          errors: {
            [BASE_ERROR_KEY]: error,
          },
        }),
      )
      throw error
    }

    saving = false
    return profileId
  }
}

type OnProfileDetailsEditChangeOptions = {
  disableMinorConsent?: boolean
}

const onProfileDetailsEditChangeOptionsDefaults = {
  disableMinorConsent: false,
}

export function onProfileDetailsEditChange(
  field: string,
  value: string,
  options?: OnProfileDetailsEditChangeOptions,
) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const state = getState()
    const { minorConsentEnabled } = getFeatureFlags(state)
    const profileDetailsEdit = state.profileDetailsEdit
    const { disableMinorConsent } = {
      ...onProfileDetailsEditChangeOptionsDefaults,
      ...options,
    }
    const { editableFields } = profileDetailsEdit
    let { errors } = profileDetailsEdit

    if (
      hasErrors({
        errors,
      })
    ) {
      errors = validateProfileDetailsEditInformation(
        {
          ...profileDetailsEdit,
          errors,
          editableFields,
          [field]: value,
        },
        {
          processMinorConsent: minorConsentEnabled && !disableMinorConsent,
        },
      )
    }

    if (field === 'dob' && value) {
      const age = ageNow(value)
      dispatch(toggleFieldEditable('email', age >= CAPTURE_EMAIL_ADDRESS_AGE))
    }

    dispatch(
      updateProfileDetails({
        [field]: value,
        errors,
      }),
    )
  }
}

type OnSaveOptions = {
  createAndAssignProfile?: typeof createAndAssignProfile
  disableMinorConsent?: boolean
}
const onSaveOptionsDefaults = {
  createAndAssignProfile,
  disableMinorConsent: false,
}

export function onSave(
  cartItemInternalId: string | null | undefined,
  api: API = getCustomerAPI(),
  reload: (...args: Array<any>) => any = performDefaultReloadTasks,
  onSaveOptions?: OnSaveOptions,
) {
  return async (
    dispatch: AppDispatch,
    getState: () => AppState,
  ): Promise<boolean> => {
    const state = getState()
    const {
      disableMinorConsent,
      createAndAssignProfile: _createAndAssignProfile,
    } = { ...onSaveOptionsDefaults, ...onSaveOptions }
    const { profileDetailsEdit } = state
    const { minorConsentEnabled } = getFeatureFlags(state)
    const processMinorConsent = minorConsentEnabled && !disableMinorConsent
    const errors = validateProfileDetailsEditInformation(profileDetailsEdit, {
      processMinorConsent,
    })

    dispatch(updateProfileDetails({ errors }))

    if (
      cartItemInternalId &&
      !hasErrors({
        errors,
      })
    ) {
      const profileId = await dispatch(
        _createAndAssignProfile(
          profileDetailsEdit,
          cartItemInternalId,
          api,
          reload,
        ),
      )

      if (
        ageNow(profileDetailsEdit.dob) < 13 &&
        processMinorConsent &&
        profileDetailsEdit.minorConsentApproved
      ) {
        await createMinorConsents([profileId])
      }

      return !!profileId
    }

    return false
  }
}
