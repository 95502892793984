/**
 * Defines actions and action creators related to credit cards.
 */

import { loadCreditCardOnFile } from '~/actions/credit-card-on-file'
import { addFlashMessage } from '~/actions/flash-message'
import { loadResortCharge } from '~/actions/resort-charge'
import { i18n } from '~/i18n'
import { requestCreateCreditCard } from '~/utils/api/credit-card'
import { getErrorMessage } from '~/utils/credit-card'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import type { NewCreditCard } from '~/types'

export const START_SAVING_CC = 'START_SAVING_CC'
export const STOP_SAVING_CC = 'STOP_SAVING_CC'

export function createCreditCard(
  creditCard: NewCreditCard,
  successHandler: (...args: Array<any>) => any,
) {
  return async (dispatch: (...args: Array<any>) => any) => {
    dispatch({
      type: START_SAVING_CC,
    })

    try {
      const resp = await requestCreateCreditCard(creditCard)

      if (resp.ok) {
        dispatch(
          addFlashMessage(
            'info',
            i18n.t('components.flash_messages.credit_card_create'),
            {
              navCount: 0,
              autoHide: true,
            },
          ),
        )
        dispatch({
          type: STOP_SAVING_CC,
        })
        successHandler()
        dispatch(
          loadCreditCardOnFile({
            reload: true,
          }),
        )
        dispatch(
          loadResortCharge({
            reload: true,
          }),
        )
      } else if (resp.status === 422) {
        const { errors } = await resp.json()
        const errorMessage = getErrorMessage(errors)
        dispatch(
          addFlashMessage('error', errorMessage, {
            autoHide: true,
          }),
        )
        dispatch({
          type: STOP_SAVING_CC,
        })
      } else {
        throw new HTTPError('Credit card creation failed', resp, '')
      }
    } catch (error) {
      reportGroupedError('createCreditCard', error)
      dispatch({
        type: STOP_SAVING_CC,
      })
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.credit_card_create_error'),
          {
            autoHide: true,
          },
        ),
      )
    }
  }
}
