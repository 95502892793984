import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'

export function unique<T>(array: T[]): T[] {
  return uniq(array)
}

export function initArray(count: number): null[] {
  return new Array(count).fill(null)
}

export function mapByKey<T>(key: string): (arr: T[]) => Record<string, T> {
  return function doMap(arr: T[]) {
    return keyBy(arr, key)
  }
}
