/**
 * Defines actions and action creators related to authentication.
 */
import { push } from '~/actions/navigation'
import { i18n } from '~/i18n'
import { ping } from '~/utils/api'
import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import { loadProfile } from './profile'

import type { ValueOf } from '~/types'

export const AuthError = {
  UNKNOWN: 'UNKNOWN',
  SOCIAL_NOT_REGISTERED: 'SOCIAL_NOT_REGISTERED',
  NO_MATCH: 'NO_MATCH',
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
}

export type AuthErrorCode = ValueOf<typeof AuthError>
export type AuthActionTypes = {
  type: 'AUTHENTICATING'
}

const TRANSLATED_AUTH_ERRORS = () => ({
  [AuthError.UNKNOWN]: i18n.t('components.errors.auth.default'),
  [AuthError.SOCIAL_NOT_REGISTERED]: i18n.t(
    'components.errors.auth.social_not_registered',
  ),
  [AuthError.NO_MATCH]: i18n.t('components.errors.auth.no_match'),
  [AuthError.TOO_MANY_ATTEMPTS]: i18n.t(
    'components.errors.auth.too_many_attempts',
  ),
})

//
// - Actions and Sync Action Creators
//
export const CHECKING_AUTH = 'CHECKING_AUTH'
export function checkingAuth() {
  return {
    type: CHECKING_AUTH,
  }
}

export const RESET_AUTH_REJECTED = 'RESET_AUTH_REJECTED'

export function resetAuthRejected() {
  return {
    type: RESET_AUTH_REJECTED,
  }
}

export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'

export function authenticationFailed(errorCode: AuthErrorCode) {
  const translatedError =
    TRANSLATED_AUTH_ERRORS()[errorCode] ||
    TRANSLATED_AUTH_ERRORS()[AuthError.UNKNOWN]
  return {
    type: AUTHENTICATION_FAILED,
    reason: translatedError,
  }
}

export const AUTH_CHECKED = 'AUTH_CHECKED'
export function authChecked(loggedIn: boolean) {
  return {
    type: AUTH_CHECKED,
    loggedIn,
  }
}

export const AUTHENTICATING = 'AUTHENTICATING'

function authenticating(): AuthActionTypes {
  return {
    type: AUTHENTICATING,
  }
}

export const AUTHENTICATED = 'AUTHENTICATED'
export function authenticated() {
  return {
    type: AUTHENTICATED,
  }
}

export const LOGGED_OUT = 'LOGGED_OUT'
export function loggedOut() {
  return {
    type: LOGGED_OUT,
  }
}

//
// - Async Action Creators
//

/**
 * login attempts to authenticate with username and password.
 *
 * Actions dispatched:
 *  Waiting         -> AUTHENTICATING
 *  User authed     -> AUTHENTICATED
 *  User not authed -> AUTHENTICATION_FAILED
 *  Server error    -> AUTHENTICATION_FAILED
 *  Network error   -> AUTHENTICATION_FAILED
 *
 * @param  {String} code OAuth authorization code to authenticate the user
 *                       with.
 * @return {Function}    Thunk which will initiate the request to the
 *                       server.
 */
let loggingIn = false
export function login(email: string, password: string) {
  return async (dispatch: (...args: Array<any>) => any) => {
    if (loggingIn) {
      return
    }

    loggingIn = true
    dispatch(authenticating())

    // Ping to renew authenticity token before login.
    await ping()

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    }

    try {
      const resp = await authedFetch('/session', options, null)

      if (resp.ok) {
        // handle temporary password reset
        if (resp.status === 200) {
          const json = await resp.json()
          return dispatch(push(json.redirect_url))
        }

        await dispatch(
          loadProfile({
            reload: true,
          }),
        )
        dispatch(authenticated()) // Redirect is handled by NoAuth component.
      } else if (resp.status === 422) {
        dispatch(authenticationFailed(AuthError.NO_MATCH))
      } else if (resp.status === 429) {
        dispatch(authenticationFailed(AuthError.TOO_MANY_ATTEMPTS))
      } else {
        throw new HTTPError('Unable to create session', resp, '')
      }
    } catch (error) {
      dispatch(authenticationFailed(AuthError.UNKNOWN))
      reportGroupedError('auth/login', error)
    } finally {
      loggingIn = false
    }
  }
}
