/**
 * Defines actions and action creators related to the user's profile.
 */

import { addFlashMessage } from '~/actions/flash-message'
import { guestProfileDeserializer } from '~/deserializers/profile'
import { i18n } from '~/i18n'
import { authedFetch } from '~/utils/fetch'
import { authenticationSerializer } from '~/utils/gtm-data-layer-serializers'
import HTTPError from '~/utils/http-error'
import { inputReplaceMap } from '~/utils/input-replace'
import { reportGroupedError } from '~/utils/logger'

import { PasswordState, updatePasswordStatus } from './ui/profile'

import type { GuestProfile } from '~/types'

type Dispatch = (...args: Array<any>) => any
type GetState = () => Record<string, any> //
// - Actions and Sync Action Creators
//

export const PROFILE_UPDATE = 'PROFILE_UPDATE'

export function profileUpdate(profile: Record<string, any>) {
  profile = inputReplaceMap(profile)
  return {
    type: PROFILE_UPDATE,
    profile,
  }
}

function profileLoaded(profile: GuestProfile) {
  return profileUpdate(
    Object.assign({}, profile, {
      loaded: true,
      loadFailed: false,
    }),
  )
}

function profileLoadFailed() {
  return profileUpdate({
    loaded: false,
    loadFailed: true,
  })
}

export function updatePasswordSuccess() {
  return {
    type: PROFILE_UPDATE,
    profile: {
      errors: {},
    },
  }
}

export function updatePasswordFailure({
  errors,
}: {
  errors: Record<string, string[]>
}) {
  return {
    type: PROFILE_UPDATE,
    profile: {
      errors: {
        currentPassword: errors.current_password,
      },
    },
  }
}

//
// - Async Action Creators
//

/**
 * loadProfile makes a request to the server for the current user's profile
 * information. It only makes a request if there is not one in play.
 *
 * Actions dispatched:
 *  Profile information fetched  -> PROFILE_UPDATE
 *
 * @return {Function}  Thunk which will initiate the request to the server.
 */
let fetching = false
type LoadProfileOptions = {
  reload?: boolean
}
export function loadProfile(options: LoadProfileOptions = {}) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (fetching || (!options.reload && getState().profile.loaded)) return
    fetching = true
    dispatch(
      profileUpdate({
        loadFailed: false,
      }),
    )
    return authedFetch('/api/v2/me', {}, null)
      .then((resp) => {
        fetching = false

        if (resp.ok) {
          return resp.json().then(({ data }) => {
            if (!options.reload) {
              window.dataLayer.push(authenticationSerializer(data.profile))
            }

            dispatch(profileLoaded(guestProfileDeserializer(data)))
          })
        } else if (resp.status === 401) {
          dispatch(profileLoadFailed())
        } else {
          return Promise.reject(
            new HTTPError('load profile unknown error', resp, ''),
          )
        }
      })
      .catch((error) => {
        fetching = false
        dispatch(profileLoadFailed())
        reportGroupedError('loadProfile', error)
      })
  }
}

/**
 * updatePassword makes a request to the server to update the users password.
 * It only makes a request if there is not one in play.
 *
 * @return {Function}  Thunk which will initiate the request to the server.
 */
let updatingPassword = false
export function updatePassword({
  currentPassword,
  password,
}: {
  currentPassword: string
  password: string
}) {
  return (dispatch: Dispatch) => {
    if (updatingPassword) return
    updatingPassword = true
    dispatch(updatePasswordStatus(PasswordState.PROCESSING))
    const opts = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          current_password: currentPassword,
          password,
        },
      }),
    }
    authedFetch('/api/v2/me', opts)
      .then((resp) => {
        updatingPassword = false

        if (resp.ok) {
          dispatch(
            addFlashMessage(
              'info',
              i18n.t('components.flash_messages.password_updated'),
              {
                glyph: 'info',
                autoHide: true,
              },
            ),
          )
          dispatch(updatePasswordSuccess())
          dispatch(updatePasswordStatus(PasswordState.SUCCESS))
        } else if (resp.status === 422) {
          dispatch(updatePasswordStatus(PasswordState.FAILED))
          return resp.json().then((json) => {
            dispatch(updatePasswordFailure(json))
          })
        } else {
          dispatch(updatePasswordStatus(PasswordState.FAILED))
          return Promise.reject(
            new HTTPError('update password unknown error', resp, ''),
          )
        }
      })
      .catch((error) => {
        updatingPassword = false
        dispatch(updatePasswordStatus(PasswordState.FAILED))
        reportGroupedError('updatePassword', error)
        dispatch(
          addFlashMessage(
            'error',
            i18n.t('components.flash_messages.password_update_error'),
            {
              autoHide: true,
            },
          ),
        )
      })
  }
}
