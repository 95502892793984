/**
 * Component to handle showing content in a modal dialog. This overlays over
 * the current view.
 */
import DoubleBounceSpinner from '../double-bounce-spinner'

import classNames from 'classnames'
import React, { Component, useRef } from 'react'
import { createPortal } from 'react-dom'
import OverflowScrolling from 'react-overflow-scrolling'

import './modal.scss'
import ModalActions from './modal-actions'
import Button from '../button'
import Icon from '../icon'

import type { Props as ModalActionsProps } from './modal-actions'
import type { ReactNode } from 'react'

type ModalSize = 'small' | 'medium' | 'large'

export type Props = {
  children: ReactNode
  size?: ModalSize
  className?: string
  onClose?: () => void
  title?: string
  actions?: ModalActionsProps
  loading?: boolean
  loadingContent?: ReactNode
  actionPanel?: ReactNode
}

export class Modal extends Component<Props> {
  static defaultProps = {
    size: 'medium',
  }

  showHeader() {
    return this.props.onClose || this.props.title
  }

  renderTitle() {
    if (this.props.title) {
      return <h2 data-testid="title">{this.props.title}</h2>
    }
  }

  renderCloseButton() {
    if (this.props.onClose) {
      return (
        <Button
          id="modal-close"
          data-testid="modal-close"
          type={['inline', 'icon']}
          className="close"
          onClick={this.props.onClose}
        >
          <Icon glyph="close" />
        </Button>
      )
    }
  }

  renderHeader() {
    if (this.props.onClose || this.props.title) {
      return (
        <header className="header">
          {this.renderTitle()}
          {!this.props.loading && this.renderCloseButton()}
        </header>
      )
    }
  }

  render() {
    const modalClass = classNames(
      'amp-modal',
      'amp-base',
      this.props.className,
      this.props.size,
      {
        loading: this.props.loading,
      },
    )
    return (
      <OverflowScrolling className={modalClass}>
        <article data-testid="modal" role="complementary">
          {this.renderHeader()}
          <div className="body">
            {this.props.loading && <DoubleBounceSpinner />}
            {this.props.loading && this.props.loadingContent && (
              <section className="loading-content">
                {this.props.loadingContent}
              </section>
            )}
            <section className="content"> {this.props.children} </section>
            {this.props.actions && <ModalActions {...this.props.actions} />}
            {this.props.actionPanel}
          </div>
        </article>
      </OverflowScrolling>
    )
  }
}

export default (props: Props) => {
  const el = useRef(document.getElementById('modal'))
  return el.current ? createPortal(<Modal {...props} />, el.current) : null
}

export { ActionPanel } from './action-panel.styles'
